<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Сегодня задействуем мелкую моторику :)</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Скрепки нерефленные 5см</li>
                <li>Круглогубцы, плоскогубцы, кусачки (опционально, если в у плоскогубцев есть режущий элемент, то кусачки необязательны)</li>
                <li>Ватный диск</li>
                <li>Клей ПВА столяр + кисть</li>
                <li>Шкурки. Жесткой зернистости (армированная сетка, напильник), средней зернистости</li>
                <li>Стек-клюшка</li>
                <li>Пластырь на тканной основе</li>
                <li>Следок (обычный женский)</li>
                <li>Полотно для ножовки</li>
                <li>Макетный нож</li>
                <li>Круглый и плоский надфиль (можно алмазный, можно обычный)</li>
                <li>Линейка с круглыми отверстиями</li>
            </ul>
            <h3>1. Делаем заготовки пальчиков</h3>
        </div>

        <VideoView video-id="0c543b3ee037487687e86f0b0b8737b3"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Отмечаем на чертеже длину пальцев (на 1,5мм меньше сверху и 5мм внизу не доходя до шарнира)</li>
                <li>Нарезаем скрепку по длине пальчиков (все скругленные участки просто отрезаем, мы их не используем). Скрепка — арматура, чтобы пальцы были крепкими и не ломались. Цветочную проволоку не используем, она слишком мягкая!</li>
                <li>Смазываем проволовку клеем и покрываем тонким слоем ваты (вата нужна для абразивного слоя, чтобы пластику было за что зацепиться), прокручиваем. Следим, чтобы вся проволока была покрыта. Кончики ваты обрезаем. </li>
                <li>Сгибаем пальцы, ориентируясь на выбранное вами положение руки</li>
                <li>Обтягиваем пальчики. Существует 2 метода обтяжки пальцев пластиком:</li>
                <ol type='a'>
                    <li><b>Метод чулка.</b> Раскатываем сосиску толщиной с палец (сверьтесь по чертежу), делаем в ней отверстие проволокой, не доходя до конца 1мм. Одеваем на сосиску нашу заготовку. Формируем красивую форму пальца (скругленно снизу, сверху плоская платформа)</li>
                    <li><b>Метод завертывания.</b> Возьмите небольшой кусок пластика толщиной 1,5мм и оберниту заготовку пластиком, примазывая его к проволоке и создавая форму пальца (обратите внимание, что арматура должна не доходить до конца 1,5мм, но не больше иначе пальчик может сломаться в том месте, где ее нет)</li>
                </ol>
                <li>Теперь пальцы нужно быстро высушить, чтобы ржавчина не успела образоваться (батарея, духовка до 70гр с открытой дверцей, стакан с феном)</li>
            </ol>
            
            <h3>2. Лепим кисть </h3>
        </div>

        <VideoView video-id="f6ea0f9b77974e37a7ba735805b86506"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Шкурим все пальчики</li>
                <li>Создаем основу ладони по чертежу (прямоугольник толщиной 5мм)</li>
                <li>Чертим на ладони середину</li>
                <li>Начинаем вставлять пальцы со среднего, аккуратно примазывая к основе. Проверяйте ладошку по чертежу, чтобы длина пальцев соответствовала. Также  следите, чтобы плоская поверхность пальца смотрела вверх, а скругленная вовнуть ладони. </li>
                <li>Поиграйте с наклоном каждого пальчика, создайте ту форму, которая вам нравится. Большой палец опущен вниз. </li>
                <li>Положите ладошку на ваш палец и скруглите ее.</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/hand1.jpg')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-hand.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>2. Создаем основу стопы </h3>
        </div>

        <VideoView video-id="b0a81133baf34128a5f6476b97068fc3"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Намечаем контуры арматуры (по чертежу на 3мм вглубь)</li>
                <li>Сгибаем проволоку так, чтобы она повторяла контур</li>
                <li>Обворачиваем проволоку пластырем, углы срезаем, так чтобы не оголить проволоку.</li>
                <li>Обтягиваем основу пластиком в 3 мм, так чтобы не потерять форму заготовки (лучше убрать лишний пластик)</li>
                <li>Оставляем сохнуть</li>
            </ol>
            
            <h3>3. Создаем основу стопы. Продолжение </h3>
        </div>

        <VideoView video-id="e7cfc7179dc14575b5a490fee216648e"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Обрисовываем на чертеже контур получившейся заготовки, чтобы оценить сколько пластика еще нужно долепить</li>
                <li>Формируем стопу</li>
            </ol>       

            <a @click="showImage('/files/BodoirV2/feet.jpg')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-feet.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>4. Анатомия груди. Часть 1</h3>
        </div>

        <VideoView video-id="75f782eea8f14d09b9d32bf107c4cdbf"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Проверям заготовку на наличие трещин, если есть, расширяем, заполняем пластиком</li>
                <li>Сначала выглаживаем поверхность жесткой шкуркой, излишки пыли убираем влажным следком. Потом шкуркой средней зернистости.</li>
                <li>Определяем центры спереди и на спине</li>
                <li>Проверяем заготовку на симметрию, прокручивая, если что-то не так правим.</li>
                <li>Выравниваем нижний и верхний срез детали</li>
                <li>Добавляем плечи по трафартеу</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/chest1.jpg')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-chest.png')" alt="" width="144"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
            
            <h3>5. Анатомия ноги</h3>
        </div>

        <VideoView video-id="c22f0e54dc454d5a99b3970d26851e03"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Сначала выглаживаем поверхность жесткой шкуркой</li>
                <li>Определеяем, где будет перед ноги (проводим центральную линию)</li>
                <li>Определяем будет ли эта правая или левая нога.</li>
                <li>Сначала всегда работаем по-сухому, убираем все лишнее, шкурим и только, когда все операции по-сухому сделаны, переходим к работе с пластиком т.е. добавляем объемы.</li>
                <ol type='a'>
                    <li>Поэтому сначала вставляем ногу в трафарет, отмечаем высоту, отмечаем места, где нужно убрать пластик.</li>
                    <li>Срезаем пластик сверху, зашкуриваем срез.</li>
                    <li>Срезаем пластик снизу по бокам, оставляя юбочку.</li>
                    <li>Шкурим сначала жесткой шкуркой (армированная сетка). Чтобы нога не стала квадратной, скрутите шкурку вокруг ноги и повертите в разные стороны. Сетка отлично выровнит поверхность, уберет весь "целлюлит", но может оставить царапины (это мы выровним позже)</li>
                    <li>Смахиваем всю пыль влажным следком</li>
                </ol>                
                <li>Проверяем, не истончилась ли стенка, нужно ли добавить внутрь пластик. </li>
                <li>Как только заготовка полностью влезает в трафарет, можем начать наращивать объемы, так чтобы нога повторяла все контуры.</li>
                <li>Оставляем сохнуть</li>
            </ol>

            <h3>6. Анатомия руки</h3>
        </div>

        <VideoView video-id="9b653593d32e437b906d5ff97664d319"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Сначала выглаживаем поверхность жесткой шкуркой</li>
                <li>Определяем перед, проводим середину</li>
                <li>Вставляем в трафарет, отмечаем высоту заготовки, отмечает места, где требуется убрать пластик</li>
                <li>Подгоняем заготовку по высоте, шкурим срезы</li>
                <li>Убираем все лишнее с помощью макетного ножа</li>
                <li>Жестко шкурим, выглаживаем следком</li>
                <li>Следите, чтобы были плавные переходы от одного объема к другому, если нужно воспользуйтесь надфилем, чтобы смягчить объемы</li>
                <li>Подгоняем по трафарету со всех сторон</li>
            </ol>
        </div>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Сделать пальчики, <b>сформировать кисть</b></li>
                <li><b>Слепить стопу</b></li>
                <li><b>Отшкурить</b>  заготовку верхней части тела и <b>нарастить плечи</b></li>
                <li><b>Подогнать по трафарету ноги</b></li>
                <li><b>Подогнать по трафарету руки</b></li>
                <li><b>Подготовить материалы</b> к следующему занятию: линейка с круглыми отверстиями, шаблон для квиллинговых форм. </li>
            </ol>
           <h3>Время выполнения: 4ч</h3>
           <h3><a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js'
    import KImage from "../../Widgets/KImage";


    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src) {
                this.$swal.fire({
                    // width: '80%',
                    height: '90%',
                    background: '#eae2e0',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>